import React, { useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import LanguageContext from "../contexts/language"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Header from "../components/header/Header"
const isBrowser = typeof window !== "undefined"

const News = props => {
  const { prismicNews, allPrismicNewsArticle} = props.data;
  const data = prismicNews.data;
  const newsArticles = allPrismicNewsArticle?.nodes;

  const { theme, accesibilitySize } = useContext(LanguageContext)
  const [themeState, setThemeState] = useState(theme)
  const [accesibilitySizeState, setAccesibilitySizeState] = useState(
    accesibilitySize
  )

  useEffect(() => {
    if (isBrowser && themeState) localStorage.setItem("foris_theme", themeState)
  }, [themeState])

  useEffect(() => {
    if (isBrowser && accesibilitySizeState)
      localStorage.setItem("accesibility_size", accesibilitySizeState)
  }, [accesibilitySizeState])

  return (
    <Layout
      {...props}
      theme={themeState}
      accesibilitySize={accesibilitySizeState}
    >
      <Header/>
      <section>
        <h1>{data?.title?.text}</h1>
        <p>{data?.description?.text}</p>
      </section>
      <section>
        <h2>Noticias</h2>
        {newsArticles?.map((article)=>(
          <div style={{border: '1px solid peru'}}>
            <p>{article?.data?.article_title}</p>
          </div>
        ))}
      </section>
      <Footer />
    </Layout>
  )
}

export default News

export const pageQuery = graphql`
  query News($lang: String!) {
    prismicNews(lang: { eq: $lang }) {
      data {
        title {
          richText
          text
        }
        description {
          richText
          text
        }
      }
    }
    allPrismicNewsArticle(
      filter: {lang: {eq: $lang}}
    ) {
      nodes {
        data {
          article_homepage_order
          article_image {
            url
          }
          article_category
          article_title
          article_description
          article_link {
            url
          }
          
        }
      }
    }
  }
`
